import { config } from "process";

import * as cryptoJS from "crypto-js";

export class GlobalConstants{

    private static document_location = document.location["origin"];

    public static apiURL= GlobalConstants.document_location+"/api/";
    public static apiURL_lo = ( GlobalConstants.document_location.includes('localhost') ? GlobalConstants.apiURL : "http://crm.maxiwatt.lo/api/");

    public static user_name=localStorage.getItem('nombre');
    public static user_mail=localStorage.getItem('email');
    public static user_type=localStorage.getItem('tipo');
    public static user_id=localStorage.getItem('id');

    public static destacado_array_str = [
        {'index':'0','value':'Normal'},
        {'index':'1','value':'Importante'},
        {'index':'2','value':'Muy importante'},
        {'index':'3','value':'Pendiente de valorar'}
    ];

    public static yesno_array_str = [
      {'index':'0','value':'No'},
      {'index':'1','value':'Yes'}
    ];
  
    public static nuevo_array_str = GlobalConstants.yesno_array_str.concat([
      {'index':'2','value':'Repesca'},
      {'index':'3','value':'Cliente'}
    ]);

    public static procedencia_array_str = [
      {'index':'0','value':'Otros'},
      {'index':'1','value':'Respuesta Pregunta'},
      {'index':'2','value':'Direct Industry'},
      {'index':'3','value':'Busqueda Web'},
      
      {"index": "Directorios (IQS)",              "value": "Directorios (IQS)"},
      {"index": "Directorios (Direct Industry)", "value": "Directorios (Direct Industry)"},
      {"index": "Marketplace (NOVAUT)",           "value": "Marketplace (NOVAUT)"},
      {"index": "Marketplace (KAUFFLAND)",        "value": "Marketplace (KAUFFLAND)"},
      {"index": "Marketplace (MIRAVIA)",          "value": "Marketplace (MIRAVIA)"},
      {"index": "Marketplace (SEARS)",            "value": " Marketplace (SEARS)"},
      {"index": "Marketplace (IDEALO)",           "value": "Marketplace (IDEALO)"}
    ];
  
    public static status_array_str = [
      {'index':'0','value':''},
      {'index':'1','value':'En Espera'},
      {'index':'2','value':'Preparado'},
      {'index':'3','value':'Contactado'},
      {'index':'4','value':'Envio de muestras'},
      {'index':'5','value':'Petición de precio'},
      {'index':'6','value':'Pedido'},
      {'index':'7','value':'No interesado'},
      {'index':'8','value':'Ya es cliente'},
      {'index':'9','value':'No usa resistencias'},
      {'index':'10','value':'Envio muestras personalizadas'},
      {'index':'11','value':'Nos contactaran ellos'},
      {'index':'12','value':'Visitar Cliente'},
      {'index':'13','value':'Email presentación enviado'},
      {'index':'14','value':'Search Information'}
    ];
  
    public static lists_array_str = [
      {'index':'0','value':''},
      {'index':'1','value':'Lista 1'},
      {'index':'2','value':'Lista 2'},
      {'index':'3','value':'Lista 3'},
      {'index':'4','value':'Lista 4'},
      {'index':'5','value':'Lista 5'},
      {'index':'6','value':'Lista 6'},
      {'index':'7','value':'Lista 7'}
    ];
  
    public static sectors_array_str = [
      {'index':'0','value':''},
      {'index':'1','value':'Particular'},
      {'index':'2','value':'Plasticos'},
      {'index':'3','value':'Moldes'},
      {'index':'4','value':'Embalaje'},
      {'index':'5','value':'Comerciales'},
      {'index':'6','value':'Fabricantes  Resistencias'},
      {'index':'7','value':'Fabricantes Maquina'},
      {'index':'8','value':'Fabricantes de Cuchillas'},
      {'index':'9','value':'Automoción'},
      {'index':'10','value':'Miriam'},
      {'index':'11','value':'Estufas pellet'}
    ];
  
    public static products_array_str = [
      {'index':'0','value':''},
      {'index':'1','value':'Maxiwatt AC'},
      {'index':'2','value':'LHT'},
      {'index':'3','value':'Normawatt BBC'},
      {'index':'4','value':'Moldeables'},
      {'index':'5','value':'EXPAN'},
      {'index':'6','value':'3D'},
      {'index':'7','value':'Premium'}
    ];

    public static offer_state_array_str = [
      {'index':'0','value':''},
      {'index':'1','value':'Ofertada'},
      {'index':'2','value':'1 Recordatorio'},
      {'index':'3','value':'2 Recordatorio'},
      {'index':'4','value':'Pedido'},
      {'index':'5','value':'No aceptado'}
    ];

    public static shipping_agencies_array_str = [
      {'index':'ups' , 'value' : 'UPS'},
      {'index':'envialia' , 'value' : 'Envialia'},
      {'index':'mrw' , 'value' : 'MRW'}
    ];

    public static comercials_array_str = [ //Inspirated in usuario table
      {'index':'0' , 'value' : ''},
      {'index':'1' , 'value' : 'Gema'},
      {'index':'2' , 'value' : 'Sonia'},
      {'index':'3' , 'value' : 'Esther'},
      {'index':'4' , 'value' : 'Isabel'},
      //{'index':'5' , 'value' : ''},
      //{'index':'6' , 'value' : ''},
      //{'index':'7' , 'value' : ''},
      //{'index':'8' , 'value' : ''},
      {'index':'9' , 'value' : 'Ana'},
      {'index':'10' , 'value' : 'Erica'},
      {'index':'11' , 'value' : 'Olga'},
      //{'index':'12' , 'value' : ''},
      {'index':'13' , 'value' : 'Lise'},
      //{'index':'14' , 'value' : ''},
      {'index':'15' , 'value' : 'Mar'},
      {'index':'16' , 'value' : 'Caroline'},
      {'index':'17' , 'value' : 'Jessica'},
      {'index':'18' , 'value' : 'Paqui'},
      {'index':'19' , 'value' : 'Pedro'},
      //{'index':'20' , 'value' : ''},
      {'index':'21' , 'value' : 'Lara'},
      {'index':'22' , 'value' : 'Alba'},
      {'index':'23' , 'value' : 'Elena'},
      {'index':'24' , 'value' : 'Marta'},
      {'index':'25' , 'value' : 'Natalia'},
      {'index':'26' , 'value' : 'IGNORAR'},
      {'index':'27' , 'value' : 'Alejandro'}
    ];

    public static users_array_str = GlobalConstants.comercials_array_str.concat([
      {'index':'14','value':'Marketing'},
      {'index':'20','value':'Documenta'}
    ]);

    public static countries_array_str = [
      {"index": "AF", "code3": "AFG", "value": "Afghanistan", "number": "004"},
      {"index": "AL", "code3": "ALB", "value": "Albania", "number": "008"},
      {"index": "DZ", "code3": "DZA", "value": "Algeria", "number": "012"},
      {"index": "AS", "code3": "ASM", "value": "American Samoa", "number": "016"},
      {"index": "AD", "code3": "AND", "value": "Andorra", "number": "020"},
      {"index": "AO", "code3": "AGO", "value": "Angola", "number": "024"},
      {"index": "AI", "code3": "AIA", "value": "Anguilla", "number": "660"},
      {"index": "AQ", "code3": "ATA", "value": "Antarctica", "number": "010"},
      {"index": "AG", "code3": "ATG", "value": "Antigua and Barbuda", "number": "028"},
      {"index": "AR", "code3": "ARG", "value": "Argentina", "number": "032"},
      {"index": "AM", "code3": "ARM", "value": "Armenia", "number": "051"},
      {"index": "AW", "code3": "ABW", "value": "Aruba", "number": "533"},
      {"index": "AU", "code3": "AUS", "value": "Australia", "number": "036"},
      {"index": "AT", "code3": "AUT", "value": "Austria", "number": "040"},
      {"index": "AZ", "code3": "AZE", "value": "Azerbaijan", "number": "031"},
      {"index": "BS", "code3": "BHS", "value": "Bahamas (the)", "number": "044"},
      {"index": "BH", "code3": "BHR", "value": "Bahrain", "number": "048"},
      {"index": "BD", "code3": "BGD", "value": "Bangladesh", "number": "050"},
      {"index": "BB", "code3": "BRB", "value": "Barbados", "number": "052"},
      {"index": "BY", "code3": "BLR", "value": "Belarus", "number": "112"},
      {"index": "BE", "code3": "BEL", "value": "Belgium", "number": "056"},
      {"index": "BZ", "code3": "BLZ", "value": "Belize", "number": "084"},
      {"index": "BJ", "code3": "BEN", "value": "Benin", "number": "204"},
      {"index": "BM", "code3": "BMU", "value": "Bermuda", "number": "060"},
      {"index": "BT", "code3": "BTN", "value": "Bhutan", "number": "064"},
      {"index": "BO", "code3": "BOL", "value": "Bolivia (Plurinational State of)", "number": "068"},
      {"index": "BQ", "code3": "BES", "value": "Bonaire, Sint Eustatius and Saba", "number": "535"},
      {"index": "BA", "code3": "BIH", "value": "Bosnia and Herzegovina", "number": "070"},
      {"index": "BW", "code3": "BWA", "value": "Botswana", "number": "072"},
      {"index": "BV", "code3": "BVT", "value": "Bouvet Island", "number": "074"},
      {"index": "BR", "code3": "BRA", "value": "Brazil", "number": "076"},
      {"index": "IO", "code3": "IOT", "value": "British Indian Ocean Territory (the)", "number": "086"},
      {"index": "BN", "code3": "BRN", "value": "Brunei Darussalam", "number": "096"},
      {"index": "BG", "code3": "BGR", "value": "Bulgaria", "number": "100"},
      {"index": "BF", "code3": "BFA", "value": "Burkina Faso", "number": "854"},
      {"index": "BI", "code3": "BDI", "value": "Burundi", "number": "108"},
      {"index": "CV", "code3": "CPV", "value": "Cabo Verde", "number": "132"},
      {"index": "KH", "code3": "KHM", "value": "Cambodia", "number": "116"},
      {"index": "CM", "code3": "CMR", "value": "Cameroon", "number": "120"},
      {"index": "CA", "code3": "CAN", "value": "Canada", "number": "124"},
      {"index": "KY", "code3": "CYM", "value": "Cayman Islands (the)", "number": "136"},
      {"index": "CF", "code3": "CAF", "value": "Central African Republic (the)", "number": "140"},
      {"index": "TD", "code3": "TCD", "value": "Chad", "number": "148"},
      {"index": "CL", "code3": "CHL", "value": "Chile", "number": "152"},
      {"index": "CN", "code3": "CHN", "value": "China", "number": "156"},
      {"index": "CX", "code3": "CXR", "value": "Christmas Island", "number": "162"},
      {"index": "CC", "code3": "CCK", "value": "Cocos (Keeling) Islands (the)", "number": "166"},
      {"index": "CO", "code3": "COL", "value": "Colombia", "number": "170"},
      {"index": "KM", "code3": "COM", "value": "Comoros (the)", "number": "174"},
      {"index": "CD", "code3": "COD", "value": "Congo (the Democratic Republic of the)", "number": "180"},
      {"index": "CG", "code3": "COG", "value": "Congo (the)", "number": "178"},
      {"index": "CK", "code3": "COK", "value": "Cook Islands (the)", "number": "184"},
      {"index": "CR", "code3": "CRI", "value": "Costa Rica", "number": "188"},
      {"index": "HR", "code3": "HRV", "value": "Croatia", "number": "191"},
      {"index": "CU", "code3": "CUB", "value": "Cuba", "number": "192"},
      {"index": "CW", "code3": "CUW", "value": "Curaçao", "number": "531"},
      {"index": "CY", "code3": "CYP", "value": "Cyprus", "number": "196"},
      {"index": "CZ", "code3": "CZE", "value": "Czechia", "number": "203"},
      {"index": "CI", "code3": "CIV", "value": "Côte d'Ivoire", "number": "384"},
      {"index": "DK", "code3": "DNK", "value": "Denmark", "number": "208"},
      {"index": "DJ", "code3": "DJI", "value": "Djibouti", "number": "262"},
      {"index": "DM", "code3": "DMA", "value": "Dominica", "number": "212"},
      {"index": "DO", "code3": "DOM", "value": "Dominican Republic (the)", "number": "214"},
      {"index": "EC", "code3": "ECU", "value": "Ecuador", "number": "218"},
      {"index": "EG", "code3": "EGY", "value": "Egypt", "number": "818"},
      {"index": "SV", "code3": "SLV", "value": "El Salvador", "number": "222"},
      {"index": "GQ", "code3": "GNQ", "value": "Equatorial Guinea", "number": "226"},
      {"index": "ER", "code3": "ERI", "value": "Eritrea", "number": "232"},
      {"index": "EE", "code3": "EST", "value": "Estonia", "number": "233"},
      {"index": "SZ", "code3": "SWZ", "value": "Eswatini", "number": "748"},
      {"index": "ET", "code3": "ETH", "value": "Ethiopia", "number": "231"},
      {"index": "FK", "code3": "FLK", "value": "Falkland Islands (the) [Malvinas]", "number": "238"},
      {"index": "FO", "code3": "FRO", "value": "Faroe Islands (the)", "number": "234"},
      {"index": "FJ", "code3": "FJI", "value": "Fiji", "number": "242"},
      {"index": "FI", "code3": "FIN", "value": "Finland", "number": "246"},
      {"index": "FR", "code3": "FRA", "value": "France", "number": "250"},
      {"index": "GF", "code3": "GUF", "value": "French Guiana", "number": "254"},
      {"index": "PF", "code3": "PYF", "value": "French Polynesia", "number": "258"},
      {"index": "TF", "code3": "ATF", "value": "French Southern Territories (the)", "number": "260"},
      {"index": "GA", "code3": "GAB", "value": "Gabon", "number": "266"},
      {"index": "GM", "code3": "GMB", "value": "Gambia (the)", "number": "270"},
      {"index": "GE", "code3": "GEO", "value": "Georgia", "number": "268"},
      {"index": "DE", "code3": "DEU", "value": "Germany", "number": "276"},
      {"index": "GH", "code3": "GHA", "value": "Ghana", "number": "288"},
      {"index": "GI", "code3": "GIB", "value": "Gibraltar", "number": "292"},
      {"index": "GR", "code3": "GRC", "value": "Greece", "number": "300"},
      {"index": "GL", "code3": "GRL", "value": "Greenland", "number": "304"},
      {"index": "GD", "code3": "GRD", "value": "Grenada", "number": "308"},
      {"index": "GP", "code3": "GLP", "value": "Guadeloupe", "number": "312"},
      {"index": "GU", "code3": "GUM", "value": "Guam", "number": "316"},
      {"index": "GT", "code3": "GTM", "value": "Guatemala", "number": "320"},
      {"index": "GG", "code3": "GGY", "value": "Guernsey", "number": "831"},
      {"index": "GN", "code3": "GIN", "value": "Guinea", "number": "324"},
      {"index": "GW", "code3": "GNB", "value": "Guinea-Bissau", "number": "624"},
      {"index": "GY", "code3": "GUY", "value": "Guyana", "number": "328"},
      {"index": "HT", "code3": "HTI", "value": "Haiti", "number": "332"},
      {"index": "HM", "code3": "HMD", "value": "Heard Island and McDonald Islands", "number": "334"},
      {"index": "VA", "code3": "VAT", "value": "Holy See (the)", "number": "336"},
      {"index": "HN", "code3": "HND", "value": "Honduras", "number": "340"},
      {"index": "HK", "code3": "HKG", "value": "Hong Kong", "number": "344"},
      {"index": "HU", "code3": "HUN", "value": "Hungary", "number": "348"},
      {"index": "IS", "code3": "ISL", "value": "Iceland", "number": "352"},
      {"index": "IN", "code3": "IND", "value": "India", "number": "356"},
      {"index": "ID", "code3": "IDN", "value": "Indonesia", "number": "360"},
      {"index": "IR", "code3": "IRN", "value": "Iran (Islamic Republic of)", "number": "364"},
      {"index": "IQ", "code3": "IRQ", "value": "Iraq", "number": "368"},
      {"index": "IE", "code3": "IRL", "value": "Ireland", "number": "372"},
      {"index": "IM", "code3": "IMN", "value": "Isle of Man", "number": "833"},
      {"index": "IL", "code3": "ISR", "value": "Israel", "number": "376"},
      {"index": "IT", "code3": "ITA", "value": "Italy", "number": "380"},
      {"index": "JM", "code3": "JAM", "value": "Jamaica", "number": "388"},
      {"index": "JP", "code3": "JPN", "value": "Japan", "number": "392"},
      {"index": "JE", "code3": "JEY", "value": "Jersey", "number": "832"},
      {"index": "JO", "code3": "JOR", "value": "Jordan", "number": "400"},
      {"index": "KZ", "code3": "KAZ", "value": "Kazakhstan", "number": "398"},
      {"index": "KE", "code3": "KEN", "value": "Kenya", "number": "404"},
      {"index": "KI", "code3": "KIR", "value": "Kiribati", "number": "296"},
      {"index": "KP", "code3": "PRK", "value": "Korea (the Democratic People's Republic of)", "number": "408"},
      {"index": "KR", "code3": "KOR", "value": "Korea (the Republic of)", "number": "410"},
      {"index": "KW", "code3": "KWT", "value": "Kuwait", "number": "414"},
      {"index": "KG", "code3": "KGZ", "value": "Kyrgyzstan", "number": "417"},
      {"index": "LA", "code3": "LAO", "value": "Lao People's Democratic Republic (the)", "number": "418"},
      {"index": "LV", "code3": "LVA", "value": "Latvia", "number": "428"},
      {"index": "LB", "code3": "LBN", "value": "Lebanon", "number": "422"},
      {"index": "LS", "code3": "LSO", "value": "Lesotho", "number": "426"},
      {"index": "LR", "code3": "LBR", "value": "Liberia", "number": "430"},
      {"index": "LY", "code3": "LBY", "value": "Libya", "number": "434"},
      {"index": "LI", "code3": "LIE", "value": "Liechtenstein", "number": "438"},
      {"index": "LT", "code3": "LTU", "value": "Lithuania", "number": "440"},
      {"index": "LU", "code3": "LUX", "value": "Luxembourg", "number": "442"},
      {"index": "MO", "code3": "MAC", "value": "Macao", "number": "446"},
      {"index": "MG", "code3": "MDG", "value": "Madagascar", "number": "450"},
      {"index": "MW", "code3": "MWI", "value": "Malawi", "number": "454"},
      {"index": "MY", "code3": "MYS", "value": "Malaysia", "number": "458"},
      {"index": "MV", "code3": "MDV", "value": "Maldives", "number": "462"},
      {"index": "ML", "code3": "MLI", "value": "Mali", "number": "466"},
      {"index": "MT", "code3": "MLT", "value": "Malta", "number": "470"},
      {"index": "MH", "code3": "MHL", "value": "Marshall Islands (the)", "number": "584"},
      {"index": "MQ", "code3": "MTQ", "value": "Martinique", "number": "474"},
      {"index": "MR", "code3": "MRT", "value": "Mauritania", "number": "478"},
      {"index": "MU", "code3": "MUS", "value": "Mauritius", "number": "480"},
      {"index": "YT", "code3": "MYT", "value": "Mayotte", "number": "175"},
      {"index": "MX", "code3": "MEX", "value": "Mexico", "number": "484"},
      {"index": "FM", "code3": "FSM", "value": "Micronesia (Federated States of)", "number": "583"},
      {"index": "MD", "code3": "MDA", "value": "Moldova (the Republic of)", "number": "498"},
      {"index": "MC", "code3": "MCO", "value": "Monaco", "number": "492"},
      {"index": "MN", "code3": "MNG", "value": "Mongolia", "number": "496"},
      {"index": "ME", "code3": "MNE", "value": "Montenegro", "number": "499"},
      {"index": "MS", "code3": "MSR", "value": "Montserrat", "number": "500"},
      {"index": "MA", "code3": "MAR", "value": "Morocco", "number": "504"},
      {"index": "MZ", "code3": "MOZ", "value": "Mozambique", "number": "508"},
      {"index": "MM", "code3": "MMR", "value": "Myanmar", "number": "104"},
      {"index": "NA", "code3": "NAM", "value": "Namibia", "number": "516"},
      {"index": "NR", "code3": "NRU", "value": "Nauru", "number": "520"},
      {"index": "NP", "code3": "NPL", "value": "Nepal", "number": "524"},
      {"index": "NL", "code3": "NLD", "value": "Netherlands (the)", "number": "528"},
      {"index": "NC", "code3": "NCL", "value": "New Caledonia", "number": "540"},
      {"index": "NZ", "code3": "NZL", "value": "New Zealand", "number": "554"},
      {"index": "NI", "code3": "NIC", "value": "Nicaragua", "number": "558"},
      {"index": "NE", "code3": "NER", "value": "Niger (the)", "number": "562"},
      {"index": "NG", "code3": "NGA", "value": "Nigeria", "number": "566"},
      {"index": "NU", "code3": "NIU", "value": "Niue", "number": "570"},
      {"index": "NF", "code3": "NFK", "value": "Norfolk Island", "number": "574"},
      {"index": "MP", "code3": "MNP", "value": "Northern Mariana Islands (the)", "number": "580"},
      {"index": "NO", "code3": "NOR", "value": "Norway", "number": "578"},
      {"index": "OM", "code3": "OMN", "value": "Oman", "number": "512"},
      {"index": "PK", "code3": "PAK", "value": "Pakistan", "number": "586"},
      {"index": "PW", "code3": "PLW", "value": "Palau", "number": "585"},
      {"index": "PS", "code3": "PSE", "value": "Palestine, State of", "number": "275"},
      {"index": "PA", "code3": "PAN", "value": "Panama", "number": "591"},
      {"index": "PG", "code3": "PNG", "value": "Papua New Guinea", "number": "598"},
      {"index": "PY", "code3": "PRY", "value": "Paraguay", "number": "600"},
      {"index": "PE", "code3": "PER", "value": "Peru", "number": "604"},
      {"index": "PH", "code3": "PHL", "value": "Philippines (the)", "number": "608"},
      {"index": "PN", "code3": "PCN", "value": "Pitcairn", "number": "612"},
      {"index": "PL", "code3": "POL", "value": "Poland", "number": "616"},
      {"index": "PT", "code3": "PRT", "value": "Portugal", "number": "620"},
      {"index": "PR", "code3": "PRI", "value": "Puerto Rico", "number": "630"},
      {"index": "QA", "code3": "QAT", "value": "Qatar", "number": "634"},
      {"index": "MK", "code3": "MKD", "value": "Republic of North Macedonia", "number": "807"},
      {"index": "RO", "code3": "ROU", "value": "Romania", "number": "642"},
      {"index": "RU", "code3": "RUS", "value": "Russian Federation (the)", "number": "643"},
      {"index": "RW", "code3": "RWA", "value": "Rwanda", "number": "646"},
      {"index": "RE", "code3": "REU", "value": "Réunion", "number": "638"},
      {"index": "BL", "code3": "BLM", "value": "Saint Barthélemy", "number": "652"},
      {"index": "SH", "code3": "SHN", "value": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
      {"index": "KN", "code3": "KNA", "value": "Saint Kitts and Nevis", "number": "659"},
      {"index": "LC", "code3": "LCA", "value": "Saint Lucia", "number": "662"},
      {"index": "MF", "code3": "MAF", "value": "Saint Martin (French part)", "number": "663"},
      {"index": "PM", "code3": "SPM", "value": "Saint Pierre and Miquelon", "number": "666"},
      {"index": "VC", "code3": "VCT", "value": "Saint Vincent and the Grenadines", "number": "670"},
      {"index": "WS", "code3": "WSM", "value": "Samoa", "number": "882"},
      {"index": "SM", "code3": "SMR", "value": "San Marino", "number": "674"},
      {"index": "ST", "code3": "STP", "value": "Sao Tome and Principe", "number": "678"},
      {"index": "SA", "code3": "SAU", "value": "Saudi Arabia", "number": "682"},
      {"index": "SN", "code3": "SEN", "value": "Senegal", "number": "686"},
      {"index": "RS", "code3": "SRB", "value": "Serbia", "number": "688"},
      {"index": "SC", "code3": "SYC", "value": "Seychelles", "number": "690"},
      {"index": "SL", "code3": "SLE", "value": "Sierra Leone", "number": "694"},
      {"index": "SG", "code3": "SGP", "value": "Singapore", "number": "702"},
      {"index": "SX", "code3": "SXM", "value": "Sint Maarten (Dutch part)", "number": "534"},
      {"index": "SK", "code3": "SVK", "value": "Slovakia", "number": "703"},
      {"index": "SI", "code3": "SVN", "value": "Slovenia", "number": "705"},
      {"index": "SB", "code3": "SLB", "value": "Solomon Islands", "number": "090"},
      {"index": "SO", "code3": "SOM", "value": "Somalia", "number": "706"},
      {"index": "ZA", "code3": "ZAF", "value": "South Africa", "number": "710"},
      {"index": "GS", "code3": "SGS", "value": "South Georgia and the South Sandwich Islands", "number": "239"},
      {"index": "SS", "code3": "SSD", "value": "South Sudan", "number": "728"},
      {"index": "ES", "code3": "ESP", "value": "Spain", "number": "724"},
      {"index": "LK", "code3": "LKA", "value": "Sri Lanka", "number": "144"},
      {"index": "SD", "code3": "SDN", "value": "Sudan (the)", "number": "729"},
      {"index": "SR", "code3": "SUR", "value": "Suriname", "number": "740"},
      {"index": "SJ", "code3": "SJM", "value": "Svalbard and Jan Mayen", "number": "744"},
      {"index": "SE", "code3": "SWE", "value": "Sweden", "number": "752"},
      {"index": "CH", "code3": "CHE", "value": "Switzerland", "number": "756"},
      {"index": "SY", "code3": "SYR", "value": "Syrian Arab Republic", "number": "760"},
      {"index": "TW", "code3": "TWN", "value": "Taiwan", "number": "158"},
      {"index": "TJ", "code3": "TJK", "value": "Tajikistan", "number": "762"},
      {"index": "TZ", "code3": "TZA", "value": "Tanzania, United Republic of", "number": "834"},
      {"index": "TH", "code3": "THA", "value": "Thailand", "number": "764"},
      {"index": "TL", "code3": "TLS", "value": "Timor-Leste", "number": "626"},
      {"index": "TG", "code3": "TGO", "value": "Togo", "number": "768"},
      {"index": "TK", "code3": "TKL", "value": "Tokelau", "number": "772"},
      {"index": "TO", "code3": "TON", "value": "Tonga", "number": "776"},
      {"index": "TT", "code3": "TTO", "value": "Trinidad and Tobago", "number": "780"},
      {"index": "TN", "code3": "TUN", "value": "Tunisia", "number": "788"},
      {"index": "TR", "code3": "TUR", "value": "Turkey", "number": "792"},
      {"index": "TM", "code3": "TKM", "value": "Turkmenistan", "number": "795"},
      {"index": "TC", "code3": "TCA", "value": "Turks and Caicos Islands (the)", "number": "796"},
      {"index": "TV", "code3": "TUV", "value": "Tuvalu", "number": "798"},
      {"index": "UG", "code3": "UGA", "value": "Uganda", "number": "800"},
      {"index": "UA", "code3": "UKR", "value": "Ukraine", "number": "804"},
      {"index": "AE", "code3": "ARE", "value": "United Arab Emirates (the)", "number": "784"},
      {"index": "GB", "code3": "GBR", "value": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826"},
      {"index": "UM", "code3": "UMI", "value": "United States Minor Outlying Islands (the)", "number": "581"},
      {"index": "US", "code3": "USA", "value": "United States of America (the)", "number": "840"},
      {"index": "UY", "code3": "URY", "value": "Uruguay", "number": "858"},
      {"index": "UZ", "code3": "UZB", "value": "Uzbekistan", "number": "860"},
      {"index": "VU", "code3": "VUT", "value": "Vanuatu", "number": "548"},
      {"index": "VE", "code3": "VEN", "value": "Venezuela (Bolivarian Republic of)", "number": "862"},
      {"index": "VN", "code3": "VNM", "value": "Viet Nam", "number": "704"},
      {"index": "VG", "code3": "VGB", "value": "Virgin Islands (British)", "number": "092"},
      {"index": "VI", "code3": "VIR", "value": "Virgin Islands (U.S.)", "number": "850"},
      {"index": "WF", "code3": "WLF", "value": "Wallis and Futuna", "number": "876"},
      {"index": "EH", "code3": "ESH", "value": "Western Sahara", "number": "732"},
      {"index": "YE", "code3": "YEM", "value": "Yemen", "number": "887"},
      {"index": "ZM", "code3": "ZMB", "value": "Zambia", "number": "894"},
      {"index": "ZW", "code3": "ZWE", "value": "Zimbabwe", "number": "716"},
      {"index": "AX", "code3": "ALA", "value": "Åland Islands", "number": "248"},
      
      {"index": "EU", "code3": "EUR", "value": "Europa", "number": "0"} //SOLICITADO POR NATALIA EN 2024
    ];

    public static websites_array_str = [
      {"index": "WEB ES", "value": "WEB ES"},
      {"index": "WEB FR", "value": "WEB FR"},
      {"index": "WEB DE", "value": "WEB DE"},
      {"index": "WEB US", "value": "WEB US"},
      {"index": "AMAZON ES", "value": "AMAZON ES"},
      {"index": "AMAZON DE", "value": "AMAZON DE"},
      {"index": "AMAZON FR", "value": "AMAZON FR"},
      {"index": "AMAZON IT", "value": "AMAZON IT"},
      {"index": "AMAZON NL", "value": "AMAZON NL"},
      {"index": "AMAZON BE", "value": "AMAZON BE"},
      {"index": "AMAZON SE", "value": "AMAZON SE"},
      {"index": "AMAZON UK", "value": "AMAZON UK"},
      {"index": "AMAZON PL", "value": "AMAZON PL"},
      {"index": "AMAZON US", "value": "AMAZON US"},
      {"index": "AMAZON CA", "value": "AMAZON CA"},
      {"index": "AMAZON MX", "value": "AMAZON MX"},
    ];

    public static discounts_array_str = [
      {"index": "25", "value": "25%"},
      {"index": "40", "value": "40%"},
      {"index": "Resistencia Regalo", "value": "Resistencia Regalo"}
    ];

    public static seguimiento_type_array_str = [
      {"index": "0", "value": ""},
      {"index": "1", "value": "En Captación"},
      {"index": "2", "value": "Cliente Final"}
    ];

    public static datetime_to_date(date){

      var year=new Date (date).toLocaleString('latin', { year: 'numeric'});
      var month=new Date (date).toLocaleString('latin', { month: '2-digit'});
      var day=new Date (date).toLocaleString('latin', { day: '2-digit'});
      var s='-';
  
      return (year+s+month+s+day);
    }

    public static crypto = {
      key : "46d7093c56d90794",
      iv : "df9fa46af13e5921" ,
      
      configuration : {
        keySize: 128 ,
        iv: cryptoJS.enc.Utf8.parse("df9fa46af13e5921"),                                                                                                  
        mode: cryptoJS.mode.CBC
      }
    }
}


