import { Component, OnInit } from '@angular/core';
import { OrdersService } from '../../services/orders.service';

import { FormBuilder, FormGroup, Validators, NgForm, FormControl } from '@angular/forms';

import { GlobalConstants } from 'src/app/shared/constants/global-constants';
import { GlobalTranslations } from 'src/app/shared/constants/global-translations';
import { ActivatedRoute } from '@angular/router';

declare var $: any; //JQUERY

@Component({
  selector: 'app-all',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.less']
})

export class OrdersComponent implements OnInit{

  comercials_array_str = GlobalConstants.comercials_array_str;

  orders_array: any = [];
  orders_array_filtered: any = [];
  //webs_array: any = [];
  empresas_array: any = [{nombre:""},{nombre:""}];
  moneda_array: any = [{simbolo: ""},{simbolo: ""}];

  translations =  GlobalTranslations.translations;
  translations_str : any = [];

  order : any = {idempresas: 1, moneda: 1, respuesta: {res: "", apli : ""}};

  key: string = 'id';
  reverse: boolean = true;
  sort(key){
    this.key = key;
    this.reverse = !this.reverse;
  }

  searchText: String = '';
  filterMetadata = { count: 0 };

  /*newForm: FormGroup;
  input_form_disabled = true;*/

  searchForm: FormGroup;

  state_array_str = [
    {'index':'0','value':''},
    {'index':'1','value':'✓'},
    {'index':'2','value':'X'}
  ];

  linkshare: string = '';

  constructor(private ordersservice: OrdersService , private formBuilder: FormBuilder, private route: ActivatedRoute) {

    /*this.newForm = formBuilder.group({
      'nombre' : '',
    }); */

    this.searchForm = formBuilder.group({
      date_end :  new FormControl(),
      date_start :  new FormControl(),
    }); 

  }


  ngOnInit() {

    this.get_orders();

  }

  get_orders(){
    this.ordersservice.get_orders().subscribe(
      /*data => //console.log(data)
      this.answers= data*/
      data => {

        if(data == "logout") window.location.href = '/#/login';
        else{

          /*for (let i in data['orders']){//¿TODO desde MySLQ asignar index?
            data['orders'][i].id = i;
          }*/

          this.orders_array_filtered = this.orders_array = data['orders'];
            //this.webs_array= data['webs'];
          this.empresas_array= data['empresas'];
          this.moneda_array= data['moneda'];

          this.show_modal_item_url();
        }
      }
    );    
  }

  show_modal_item_url(){

    let website = this.route.snapshot.params.website;
    let id=this.route.snapshot.params.id;

    if(  website != undefined && id != undefined){

      let item = this.orders_array[ this.orders_array.findIndex( element => element.id === id && element.website === website ) ];
      this.show_modal_edit_item(item);
      $('#ModalEdit').modal('show');
    } 
  }

  show_modal_edit_item(item, input_form_disabled = true){

    if(item.respuesta != null && (typeof item.respuesta === 'string' || item.respuesta instanceof String) ){
      item.respuesta = JSON.parse(  item.respuesta  );
    }else{
      item.respuesta = { res : null, apli : null};
    }

    if(item.state == null) item.state = 0;
    item.state_str = this.state_array_str[ item.state ].value;

    this.order = item;
    this.translations_str = this.translations[ item.idlang ] ;

    this.linkshare = "http://"+document.location.hostname+"/#/orders/"+item.website+"/"+item.id;

    /*
    this.input_form_disabled = input_form_disable;*/
  }

  submit(form: NgForm){

  }

  update_array_filters(form){

    const date_start = form.value.date_start ;
    const date_end = form.value.date_end ;

    var orders_array = this.orders_array;

    if( form.value.date_start != "" && form.value.date_start != null){

      orders_array = this.filter_by_date_start( orders_array, date_start);

    }

    if( form.value.date_end != "" && form.value.date_end != null){

      orders_array = this.filter_by_date_end( orders_array, date_end);
    }

    this.orders_array_filtered = orders_array;
  }

  date_start_onchange(form: NgForm){

    this.update_array_filters(form)
  }

  filter_by_date_start(orders_array, date_start){

    let arrayResults = [];

    arrayResults = orders_array.filter( obj => {

        return  obj.fecha >= date_start;
    });

    return arrayResults;
  }

  date_end_onchange(form: NgForm){

    this.update_array_filters(form)
  }

  filter_by_date_end(orders_array, date_end){

    let arrayResults = [];

    arrayResults = orders_array.filter( obj => {

        return  obj.fecha <= date_end;
    });

    return arrayResults;
  }

  filter_by_date_today(form = this.searchForm){

    const date = new Date();

    form.patchValue({
      date_start: this.getDate2String(date),
      date_end: this.getDate2String(date)
    })
  
    this.update_array_filters(form)
  }

  filter_by_date_week(form = this.searchForm){

    const date = new Date();

    form.patchValue({
      date_start: this.getDate2String(this.getMonday(date)),
      date_end: ""
    })

    this.update_array_filters(form)
  }

  filter_by_date_lastweek(form = this.searchForm){

    var date = new Date();
    const monday = this.getMonday( date );

    const mondayeBefore = new Date(date.setUTCDate( monday.getUTCDate()-7 ) );
    const sundayBefore = new Date(date.setUTCDate( monday.getUTCDate()-1 ) );

    form.patchValue({
      date_start: this.getDate2String( mondayeBefore ),
      date_end: this.getDate2String( sundayBefore )
    })

    this.update_array_filters(form)
  }

  getDate2String(dateObj , divider='-'){
    return dateObj.getFullYear()+divider+(dateObj.getMonth()<=9?'0':'')+(dateObj.getMonth()+1)+divider+(dateObj.getDate()<=9?'0':'')+dateObj.getDate()
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1) ; // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  filter_by_date_reset(form = this.searchForm){

    const date = new Date();

    form.patchValue({
      date_start: "",
      date_end: ""
    })

    this.update_array_filters(form)
  }
}
