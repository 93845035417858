import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Router } from '@angular/router';

import { map, tap } from 'rxjs/operators';

import * as cryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class OrdersService{

  config;

  id_user=localStorage.getItem('id');

  constructor(public http: HttpClient, private router: Router) {
    this.config = {headers : { //Creoq que no esta haceindo nada
        "Content-Type": "application/json; charset = utf-8;"
      }
    };
  }

  key = GlobalConstants.crypto.key ;
  iv = GlobalConstants.crypto.iv;

  get_orders(){

    const httpResponse = this.http.get(`${GlobalConstants.apiURL}get_orders.php`).pipe(

      /*tap(_ => 
        console.log('tap')
      ),*/
      map((res: any) => {
        /*console.log('map');
        console.log(res);*/

        let decrypted = cryptoJS.AES.decrypt(res, cryptoJS.enc.Utf8.parse(this.key), GlobalConstants.crypto.configuration).toString(cryptoJS.enc.Utf8);

        res= JSON.parse(decrypted);

        return res;
      }),
      catchError((res: HttpErrorResponse) => { // Si no responde
        /*console.log(JSON.stringify(res));
        this.router.navigate(['/']);*/

        return throwError(JSON.stringify(res));
      })
    );

    /*let httpResponse = this.http.get(
      `${GlobalConstants.apiURL}get_orders.php`
    );*/

    /*let encrypted = cryptoJS.AES.encrypt(this.msg, this.key, this.configuration).toString(); //U2FsdGVkX1+A5bM0Y4epRBtB/A2KYdupCgZXmSSu3hQ=
    console.log(encrypted);
    let decrypted = cryptoJS.AES.decrypt(encrypted, this.key, this.configuration).toString(cryptoJS.enc.Utf8);
    console.log(decrypted);*/

    console.log(httpResponse);

    return httpResponse;
  }
}
