import { Injectable } from '@angular/core';
import { User } from '../models/user.model';

import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from 'src/app/shared/constants/global-constants';
import { Router } from '@angular/router';

@Injectable()

export class UserService {

  private isUserLoggedIn;
  public usserLogged:User;

  config;

  constructor(public http: HttpClient, private router: Router) { 
  	this.isUserLoggedIn = false;
    
    this.config = {headers : {
      "Content-Type": "application/json; charset = utf-8;"
    }}; 
  }

  setUserLoggedIn(user) {
    this.isUserLoggedIn = true;
    this.usserLogged = user.user;
    localStorage.setItem('currentUser', JSON.stringify(user)); 
  }

  getUserLoggedIn() {

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if(currentUser!=null){
      this.isUserLoggedIn = true;
      this.usserLogged = currentUser;
    }else this.isUserLoggedIn = false;
    
  	return currentUser;
  }

  login(user){
    var post= this.http.post(`${GlobalConstants.apiURL}login.php`, JSON.stringify(user ,this.config));
    return post;
  }

  logout(){

    localStorage.clear();

    var post = this.http.get(`${GlobalConstants.apiURL}logout.php`);
    return post
  }

  set_password(id,name,pass,new_pass){
    var post=this.http.post(`${GlobalConstants.apiURL}set_password.php`,JSON.stringify({id,name,pass,new_pass},this.config));
    return post;
  }

  check_user_privilages_area(){

    this.getUserLoggedIn();

    if(!this.isUserLoggedIn) this.router.navigate(['/']);
  }
}